import { defineStore } from 'pinia'
import {
  type BranchOfficeDetails,
  type CompanyDetails,
  type CompanyMemberDetails,
  MembershipTypeEnum,
  CompanyDetailsStatusEnum,
  CompanyApi,
  Configuration,
} from '~/client_api'
import { axiosInstanceAuth } from '~/client_api/exios'
import { getSelectedBranchOffice, setSelectedBranchOffice } from '~/services/branchOfficeService'

export const useCompanyStore = defineStore('company', {
  state: (): CompanyDetails => ({
    address: '',
    city: '',
    country: '',
    id: 0,
    name: '',
    phone: '',
    postal_code: '',
    vat: '',
    status: CompanyDetailsStatusEnum.InProgress,
  }),
  actions: {
    storeInitial(data: CompanyDetails) {
      this.address = data.address
      this.city = data.city
      this.country = data.country
      this.id = data.id
      this.name = data.name
      this.phone = data.phone
      this.vat = data.vat
      this.postal_code = data.postal_code
      this.status = data.status
    },
  },
  getters: {
    isKroznikVerified: (state) => state.status === CompanyDetailsStatusEnum.Verified,
    isFullyVerified: (state) => state.status === CompanyDetailsStatusEnum.Verified,
  },
})

export const useCompanyMemberStore = defineStore('companyMember', {
  state: (): CompanyMemberDetails => ({
    branch_office_id: 0,
    company_id: 0,
    membership_type: MembershipTypeEnum.Operator,
    user_id: 0,
  }),
  actions: {
    storeInitial(data: CompanyMemberDetails) {
      this.branch_office_id = data.branch_office_id
      this.company_id = data.company_id
      this.membership_type = data.membership_type
    },
  },
  getters: {
    isAdmin(): boolean {
      return this.membership_type === MembershipTypeEnum.Admin
    },
    isBranchAdmin(): boolean {
      return (
        this.membership_type === MembershipTypeEnum.Admin ||
        this.membership_type === MembershipTypeEnum.BranchAdmin
      )
    },
    isOperator(): boolean {
      return (
        this.membership_type === MembershipTypeEnum.Admin ||
        this.membership_type === MembershipTypeEnum.BranchAdmin ||
        this.membership_type === MembershipTypeEnum.Operator
      )
    },
  },
})

export interface BranchOfficeState {
  branchOffices: BranchOfficeDetails[]
  selectedBranchOfficeId: number
}

export const useBranchOfficeStore = defineStore('branchOffice', {
  state: (): BranchOfficeState => {
    return {
      branchOffices: [],
      selectedBranchOfficeId: -1,
    }
  },
  actions: {
    setupWith(branchOffices: BranchOfficeDetails[]) {
      this.branchOffices = branchOffices
      const selectedBranchOfficeId = getSelectedBranchOffice()
      if (selectedBranchOfficeId !== null) {
        this.selectedBranchOfficeId = selectedBranchOfficeId
      }
      const isSelectedBranchOfficeInCompany =
        this.branchOffices.filter((bo) => bo.id === selectedBranchOfficeId).length > 0
      if (
        (selectedBranchOfficeId === null || !isSelectedBranchOfficeInCompany) &&
        this.branchOffices.length > 0
      ) {
        setSelectedBranchOffice(this.branchOffices[0].id)
        this.selectedBranchOfficeId = this.branchOffices[0].id
      }
    },
    async fetch() {
      const response = await new CompanyApi(
        new Configuration(),
        '',
        axiosInstanceAuth
      ).companyBranchOfficesList()
      this.branchOffices = response.data
      const selectedBranchOfficeId = getSelectedBranchOffice()
      if (selectedBranchOfficeId !== null) {
        this.selectedBranchOfficeId = selectedBranchOfficeId
      }
      const isSelectedBranchOfficeInCompany =
        this.branchOffices.filter((bo) => bo.id === selectedBranchOfficeId).length > 0
      if (
        (selectedBranchOfficeId === null || !isSelectedBranchOfficeInCompany) &&
        this.branchOffices.length > 0
      ) {
        setSelectedBranchOffice(this.branchOffices[0].id)
        this.selectedBranchOfficeId = this.branchOffices[0].id
      }
    },
    setNewSelectedBranch(id: number) {
      setSelectedBranchOffice(id)
      this.selectedBranchOfficeId = id
    },
  },
  getters: {
    nameMapping(): Record<number, string> {
      const map: Record<number, string> = {}
      this.branchOffices.forEach((bo) => {
        map[bo.id] = bo.name
      })
      return map
    },
    getSelectedBranchOffice(): BranchOfficeDetails | null {
      const filteredBranchOffices = this.branchOffices.filter(
        (bo) => bo.id === this.selectedBranchOfficeId
      )
      if (filteredBranchOffices.length > 0) {
        return filteredBranchOffices[0]
      }
      return null
    },
  },
})
